import React from "react";

class Title extends React.Component {
  constructor(props) {
	super(props);
}

render () {
  return (
		<div className='view-container'>
			<div className='title-container'>
			    <div className='row'>
				    <div className='col-12'>
			        <div id='title-wrap'>
					      <img id='justice-scale' src={require('./Images/JScaleEnhanced.png')} />
					      <div id='main-title'>Cheryl R. Lee</div>
			        </div>
				    </div>
				  </div>
				</div>
		  </div>
	  );
	}
}

export default Title;
