import React from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

class AffiliatePage extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollToProf = this.handleScrollToProf.bind(this);
  }

  componentDidMount() {
    AOS.init({
      duration: 2000
    });
  }

  handleScroll() {
    document.querySelector('#comm').scrollIntoView({
      behavior: 'smooth'
    });
  }

  handleScrollToProf() {
    document.querySelector('#prof').scrollIntoView({
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <div className='global-container'>
        <div className='header-image'>
					<div className='prof-pic'>
						<div className='div-to-move-a-tag'>
							<a id='professional' onClick={this.handleScrollToProf}>
								Professional
							</a>
							<p className='header-p'>Cheryl’s talent is creative collaboration. 
							She tirelessly looks for opportunities to unite corporations in 
							various businesses with community organizations to create new 
							opportunities for profitability and growth.</p>
						</div>
					</div>
					<div className='comm-pic'>
						<div className='div-to-move-a-tag'>
							<a id='community' onClick={this.handleScroll}>
								Community
							</a>
							<p className='header-p'>Cheryl considers community service a 
							fundamental part of her professional life. From the beginning 
							of her career to date she has encouraged and mentored students, 
							young professionals and entrepreneurs to become involved in 
							community endeavors. She continues to volunteer in high schools 
							programs and community college programs.</p>
						</div>
					</div>
				</div>
        <div className='global-container'>
					<h1 id='comm'>Community</h1>
          <div className= 'flex-invert'>
						<div className= 'flex-div-invert' data-aos= 'fade-right'>
							<h3 className= 'years-header'>2019-present</h3>
							<p className= 'org-name-pushed-left'>San Diego Code School - Project Sponsor</p>
						</div>
						<img id= 'logo' src= {require('../../css/images/SDCS-logo.png')} data-aos= 'fade-left'/>
					</div>
					<div className='flex-after'>
						<div className='flex-div' data-aos= 'fade-left'>
							<h3 className='years-header'>2008-present</h3>
							<p className='org-name'>Board of Directors Bay Vista Methodist Heights Inc. </p>
						</div>
						<img id= 'logo' src= {require('../../css/images/synergy-logo-updated.jpg')} data-aos= 'fade-right'/>
						<img id= 'logo' src= {require('../../css/images/b-vista-logo.png')} data-aos= 'fade-right' />
					</div>
					<div className= 'flex-invert'>
						<div className= 'flex-div-invert' data-aos= 'fade-right'>
							<h3 className= 'years-header'>2005-present</h3>
							<p className= 'org-name-pushed-left'>Counsel to Bay Vista Methodist Heights Board of Directors </p>
						</div>
						<img id= 'logo' src= {require('../../css/images/synergy-logo.jpg')} data-aos= 'fade-left'/>
						<img id= 'logo' src= {require('../../css/images/b-vista-logo.png')} data-aos= 'fade-left'/>
					</div>
					<div className = 'flex-after'>
						<div className = 'flex-div' data-aos = 'fade-left'>
							<h3 className = 'years-header'> 2005, 2000,1998</h3>
							<p className = 'org-name'>Black Entertainment & Sports Lawyer Association (BELSA)</p>
						</div>
						<img id = 'logo' src ={require('../../css/images/besla_upscaled_image_x4.jpg')} data-aos = 'fade-right' />
					</div>
					<div className = 'flex-invert'>
						<div className = 'flex-div-invert' data-aos = 'fade-right'>
							<h3 className = 'years-header'>1994-1999</h3>
							<p className = 'org-name-pushed-left'>Board of Directors North County Housing Foundation</p>
						</div>
						<img id = 'logo' src = {require('../../css/images/north-county-housing.jpeg')} data-aos = 'fade-left'/>
					</div>
					<div className = 'flex-after'>
						<div className = 'flex-div' data-aos = 'fade-left'>
							<h3 className = 'years-header'>1997-1999</h3>
							<p className = 'org-name'> Midwestern Regional People of Color Conference</p>
						</div>
						<img id = 'logo' src = {require('../../css/images/owners-of-pocc.jpg')} data-aos = 'fade-right'/>
					</div>
					<div className = 'flex-invert'>
						<div className = 'flex-div-invert' data-aos = 'fade-right'> 
							<h3 className = 'years-header'>1998</h3>
							<p className = 'org-name-pushed-left'>Served on Michigan Task Force on Race & Gender </p>
						</div>
						<img id = 'logo' src= {require('../../css/images/state-bar-michigan_upscaled_image_x4.jpg')} data-aos = 'fade-left'/>
					</div>
					<div className = 'flex-after'>
						<div className = 'flex-div' data-aos = 'fade-left'>
							<h3 className = 'years-header'>1995-1996</h3 >
							<p className = 'org-name'>Board of Directors Esperanza Housing Association </p>
						</div>
						<img id = 'logo' src = {require('../../css/images/esperanza.jpg')} data-aos = 'fade-right'/>
					</div>
					<div className = 'flex-invert'>
						<div className = 'flex-div-invet' data-aos = 'fade-right'>
							<h3 className = 'years-header'> 1995-1996</h3>
							<p className = 'org-name-pushed-left'>Baton Leadership Group</p>
						</div>
						<img id = 'logo' src = {require('../../css/images/baton-leadership.jpg')} data-aos = 'fade-left' />
					</div>   
					<div className = 'flex-after'>
						<div className = 'flex-div' data-aos = 'fade-left'>
							<h3 className = 'years-header'>1986-1992</h3>       
							<p className = 'org-name'>National Association of Urban Bankers </p>
						</div> 
						<img id = 'logo' src = {require('../../css/images/urban-financial-services-coalition.jpg')} data-aos = 'fade-right' /> 
					</div>
					<div className = 'flex-invert'>
						<div className = 'flex-div-invert' data-aos = 'fade-right'>
							<h3 className = 'years-header'>1987-1988</h3>
							<p className = 'org-name-pushed-left'>Past President San Diego Urban Bankers </p>
						</div>
						<img id = 'logo' src = {require('../../css/images/san-diego-urban-bankers.png')} data-aos = 'fade-left'/> 
					</div>
            <h1 id='prof'>Professional</h1>
            <div className='flex-after'>
              <div className='flex-div' data-aos='fade-left'>
                <p className='prof-org-name'>
                  American Bar Association | Member, Committees on Banking{' '}
                  <br></br> and Securities Regulation
                </p>
              </div>
              <img
                id='logo'
                src={require('../../css/images/aba.png')}
                data-aos='fade-right'
              />
            </div>
            <div className='flex-invert'>
              <div className='flex-div-invert' data-aos='fade-right'>
                <p className='prof-org-name-pushed-left'>
                  California Bar | Member, Real Property Law Section
                </p>
              </div>
              <img
                id='logo'
                src={require('../../css/images/california-bar.jpg')}
                data-aos='fade-left'
              />
            </div>
            <div className='flex-after'>
              <div className='flex-div' data-aos='fade-left'>
                <p className='prof-org-name'>
                  Michigan Bar | Member, Committee on Employment Law
                </p>
              </div>
              <img
                id='logo'
                src={require('../../css/images/state-bar-michigan_upscaled_image_x4.jpg')}
                data-aos='fade-right'
              />
            </div>
            <div className='flex-invert'>
              <div className='flex-div-invert' data-aos='fade-right'>
                <p className='prof-org-name-pushed-left'>
                  U.S. Southern District of California Bar | Member
                </p>
              </div>
              <img
                id='logo'
                src={require('../../css/images/ussd-court.JPG')}
                data-aos='fade-left'
              />
            </div>
            <div className='flex-after'>
              <div className='flex-div' data-aos='fade-left'>
                <p className='prof-org-name'>
                  Wolverine Bar Association | Past Member
                </p>
              </div>
              <img
                id='logo'
                src={require('../../css/images/wolverine-bar-association_upscaled_image_x4.jpg')}
                data-aos='fade-right'
              />
            </div>
            <div className='flex-invert'>
              <div className='flex-div-invert' data-aos='fade-right'>
                <p className='prof-org-name-pushed-left'>
                  Earl B. Gilliam Bar Association | Past Member
                </p>
              </div>
              <img
                id='logo'
                src={require('../../css/images/earl-b-gilliam-bar-association.png')}
                data-aos='fade-left'
              />
            </div>
            <div className='flex-after'>
              <div className='flex-div' data-aos='fade-left'>
                <p className='prof-org-name'>
                  Lawyers Club | Past Member
                </p>
              </div>
              <img
                id='logo'
                src={require('../../css/images/lawyers-club-san-diego_upscaled_image_x4 (1).jpg')}
                data-aos='fade-right'
              />
            </div>
            <div className='flex-invert'>
              <div className='flex-div-invert' data-aos='fade-right'>
                <p className='prof-org-name-pushed-left'>
                  Homer S. Brown Bar Association | Past Member
                </p>
              </div>
              <img
                id='logo'
                src={require('../../css/images/homer.jpeg')}
                data-aos='fade-left'
              />
            </div>
            <div className='flex-after'>
              <div className='flex-div' data-aos='fade-left'>
              Jack and Jill of America inc.
              </div>
              <img
                id='logo'
                src={require('../../css/images/Jack-and-Jill-logo.png')}
                data-aos='fade-right'
              />
            </div>
        </div>
      </div>
    );
  }
}

export default AffiliatePage;
