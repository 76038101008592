import React from 'react';

import { 
  HashRouter as Router, 
  Route 
} from 'react-router-dom';

import Sticky from 'react-sticky-el';

import Title from './Components/Title'
import BayVista from './Components/BayVista'
import NavBar from './Components/NavBar.jsx';
import Credentials from './Components/Credentials';
import AffiliatePage from './Components/AffiliatePage';
import Home from './Components/Home'
import Footer from './Components/Footer';
import Achievements from './Components/Achievements';
import Contact from './Components/Contact';
import Novel from './Components/Novel';
import ScrollToTop from './ScrollToTop';


export default class App extends React.Component {
  constructor(props){
    super(props)
      this.state = {
        width: window.innerWidth
      }
    }

    componentDidMount(){
      window.addEventListener('resize', () => this.setState({width: window.innerWidth }))
    }
  
render() {
  const { width } = this.state;
    if (width > 990) {
      return (
        <div>
          <Title/>
          <Sticky style = {{"z-index": '9999'}}>
            <NavBar/>
          </Sticky>
          <Router >
          <ScrollToTop />
            <Route exact path='/' component={ Home }/>
            <Route path='/novel' component={ Novel }/>
            <Route path='/bayvista' component={ BayVista } />
            <Route path='/credentials' component={ Credentials }/>
            <Route path='/achievements' component={ Achievements }/>
            <Route path='/affiliations' component={ AffiliatePage }/>
            <Route path='/contact' component= { Contact }/>
          </Router>
          <Footer/>
        </div>
      )
    } else {
      return (
        <div>
          <NavBar/>
          <Title/>
            <Router >
              <ScrollToTop />
              <Route exact path='/' component={ Home }/>
              <Route path='/novel' component={ Novel }/>
              <Route path='/bayvista' component={ BayVista } />
              <Route path='/credentials' component={ Credentials }/>
              <Route path='/achievements' component={ Achievements }/>
              <Route path='/affiliations' component={ AffiliatePage }/>
              <Route path='/contact' component= { Contact }/>
            </Router>
          <Footer/>
        </div>
      )
    }
  }
}
