import React from "react";
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experienceInfo: false,
      teachingInfo: false,
      educationInfo: false
    };
    this.experienceToggle = this.experienceToggle.bind(this);
    this.teachingToggle = this.teachingToggle.bind(this);
    this.educationToggle = this.educationToggle.bind(this);
  }
  experienceToggle() {
    if (this.state.experienceInfo === true) {
      this.setState({ experienceInfo: false });
    } else {
      this.setState({ experienceInfo: true });
    }
  }
  teachingToggle() {
    if (this.state.teachingInfo === true) {
      this.setState({ teachingInfo: false });
    } else {
      this.setState({ teachingInfo: true });
    }
  }
  educationToggle() {
    if (this.state.educationInfo === true) {
      this.setState({ educationInfo: false });
    } else {
      this.setState({ educationInfo: true });
    }
  }
	
  render() {
    return (
      <div className="overallContainer">
        <div className="customBox">
          <h1 className="pageTitle">Experience</h1>
          <div className="rowLight">
            <button className="staticButton" onClick={ this.experienceToggle }>
              Read more about Cheryl's work experience...
            </button>
            <p className='read-more'className={ this.state.experienceInfo ? "moreInfoVisible" : "moreInfoHidden" }>
						Ms. Lee's expertise in the banking arena originates from her
						years at a Southern California savings bank where she served on
						the merger team. She participated in corporate finance debt offerings and
						borrowings, retail bank branch sales & purchases, and authors the
						corresponding indenture documents,offering circulars, proxy
						statements, reorganization agreements, related employment
						contracts and Director & Officer Questionnaires. Cheryl also
						counseled executive managment on stock exercises, conflicts of
						interest, corporate structure & policy matters, SEC & Federal
						Reserve regulations, implications of strategic plans and real
						estate consequences. During those years, Ms. Lee also authored the
						Bank's CRA Statement. Simultaneously with her years as a
						professor, Cheryl counseled various businesses on the negotiation,
						mediation, drafting and review of contracts, employee stock
						option exercises, diversity inclusion, corporate governance and 
						corporate structure. She also conducts conflict resolution in connection
						with contracts and commercial leases.
            </p>
          </div>
          <div className="rowLight">
            <h2 className="header2">Bay Vista Methodist Heights</h2>
            <div className="row">
              <h4 className="col-6 header4">General Counsel</h4>
              <h4 className="col-6 header4-2">2018 - Present</h4>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">Engage Youth Co. Counsel</h2>
            <div className="row">
              <h4 className="col-6 header4">
                General Counsel,
                <br />
                Secretary to the Board of Directors
              </h4>
              <h4 className="col-6 header4-2">2019 - Present</h4>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">Thompson, Williams & Associates</h2>
            <div className="row">
              <h4 className="col-6 header4">Partner</h4>
              <h4 className="col-6 header4-2">1993 - 1996</h4>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">Great American Bank</h2>
            <div className="row">
              <h4 className="col-6 header4">Assistant Corporate Counsel</h4>
              <h4 className="col-6 header4-2">1985 - 1991</h4>
            </div>
          </div>
          <h1 className="pageTitle">Law School Service</h1>
          <div className="rowLight">
            <div className="">&nbsp;</div>
            <div className="col-12">
              <h2 className="header2 alignCenter">
                Widener University School of Law, Delaware
              </h2>
              <ul className="list">
                <li className="listItem">
                  Outstanding Faculty Advisor of the Year | 2006
                </li>
                <li className="listItem">
                  Outstanding Professor of the Year | 2006
                </li>
                <li className="listItem">
                  Academic Support Committee | 2005-2006
                </li>
                <li className="listItem">
                  Faculty Advisor, Black Law Students Association | 2005-2006
                </li>
                <li className="listItem">Admissions Committee | 2004-2005</li>
                <li className="listItem">Curriculum Committee | 2003-2004</li>
                <li className="listItem">Curriculum Committee | 2001-2002</li>
                <li className="listItem">
                  Faculty Development Committee | 2001-2002
                </li>
                <li className="listItem">
                  Golden Gate University School of Law
                </li>
              </ul>
            </div>
          </div>
          <div className="rowLight">
            <div className="">&nbsp;</div>
            <div className="col-12">
              <h2 className="header2 alignCenter">
                Thomas Jefferson School of Law
              </h2>
              <ul className="list">
                <li className="listItem">Ad Hoc Bar Committee | 1999-2000</li>
                <li className="listItem">Curriculum Committee | 1999</li>
              </ul>
            </div>
            <div className="">&nbsp;</div>
          </div>
          <div className="rowLight">
            <div className="">&nbsp;</div>
            <div className="col-12">
              <h2 className="header2 alignCenter">
                Thomas M. Cooley Law School
              </h2>
              <ul className="list">
                <li className="listItem">
                  Faculty Advisor, Public Interest Law Society | 1997-1999
                </li>
                <li className="listItem">
                  Authored 1998 ABA Cooley Enrollment and Retention Report
                </li>
                <li className="listItem">
                  Vice-Chairman Student Support and Retention Committee | 1998
                </li>
                <li className="listItem">
                  Faculty Externship Supervisor | 1997-1998
                </li>
                <li className="listItem">
                  ABA-LSD National Client Counseling Competition Judge | 1998
                </li>
                <li className="listItem">
                  Scholarship and Awards Committee | 1997
                </li>
                <li className="listItem">
                  Student Support and Retention Committee | 1996-1999
                </li>
                <li className="listItem">
                  Negotiation Competition Judge | 1996-1999
                </li>
                <li className="listItem">
                  Intra-school Moot Court Judge, national team placed 2nd |
                  1996-1998
                </li>
                <li className="listItem">
                  Co-Chair Faculty Reading Group | 1996 – 1997
                </li>
              </ul>
            </div>
            <div className="">&nbsp;</div>
          </div>
          <h1 className="pageTitle">Education</h1>
          <div className="rowLight">
            <button className="staticButton" onClick={this.educationToggle}>
              Read more about Cheryl's education...
            </button>
            <p className='read-more'className={ this.state.educationInfo ? "moreInfoVisible" : "moreInfoHidden" }>
              A 1982 Bachelor of Science in Group & Public Communications &
              Journalism recipient from Northeastern University, and a 1985
              graduate of Duquesne University School of Law, while in law school
              she won appointment to Duquesne's Appellate Moot Court Board and
              began a limited practice of law under Pennsylvania B.A.R. 321 &
              322 Certification in the areas of Condemnation, Eminent Domain and
              Aviation Law for the County of Allegheny.
            </p>
          </div>
          <div className="rowLight">
            <div className="col-12 blockPadding">
              <h3>Doctor of Jurisprudence, Transactional Concentration</h3>
              <h5>Duquesne University Pittsburgh, Pennsylvania</h5>
              <h5>1985</h5>
            </div>
          </div>
          <div className="rowLight">
            <div className="col-12 blockPadding">
              <h3>
                B.S. Communications and Journalism, Group and Public
                Communication
              </h3>
              <h5>Northeastern University Boston, Massachusetts</h5>
              <h5>1981</h5>
            </div>
          </div>
          <div className="rowLight">
            <div className="col-12 blockPadding">
              <h3>Licensed Real Estate Broker, State of California</h3>
              <h5>Completed Southwestern Securities Regulation Course</h5>
            </div>
          </div>
          <h1 className="pageTitle">Teaching</h1>
          <div className="rowLight">
            <div className="textCenter">
              <button className="staticButton" onClick={this.teachingToggle}>
                Read more about Cheryl's teaching experience...
              </button>
            </div>
            <div className="row">
              <p className='read-more' className={ this.state.teachingInfo ? "moreInfoVisible" : "moreInfoHidden" }>
                For the past 30 years, Cheryl has taught at ABA accredited law
                schools throughout the country, San Diego State University, as
                well as the San Diego Community College District and the Grossmont-Cuyamaca 
								Community College District. As an Associate Professor of Law and Adjunct 
								Professor she developed curriculum in legal education, general communication
                and educational programs which brought together the San Diego
                business and academic communities. She served on numerous
                faculty committees from 1997-2008 and was voted Outstanding
                Professor of the year in 2006 while at Golden Gate University
                School of Law. Ms. Lee's contracts expertise began in 1981
                during law school when she helped draft the original Basic Use
                Agreement for all tenants and concessionaires of the new
                Mid-Field Airport terminal which replaced the original 1952
                Greater Pittsburgh International Airport terminal. She teaches Contracts,
                Securities Regulation, Banking Law, Corporations, Agency &
                Partnership, Remedies, Real Estate Law, Employment Law,
                Commercial Law-Sales/Leases/Secured Transactions, and Small
                Business Management.
              </p>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">
              San Diego State University / Grossmont College / San Diego
              Community College District
            </h2>
            <div className="row">
              <h4 className="col-6 header4">Adjunct Professor</h4>
              <h4 className="col-6 header4-2">Present</h4>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">Western State University College of Law</h2>
            <div className="row">
              <h4 className="col-6 header4">
                Associate Professor of Law, Tenure Track
              </h4>
              <h4 className="col-6 header4-2">August 2006 - August 2008</h4>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">Golden Gate University School of Law</h2>
            <div className="row">
              <h4 className="col-6 header4">
                Associate Professor of Law, Tenure Track
              </h4>
              <h4 className="col-6 header4-2">July 2003 - August 2006</h4>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">
              Widener University School of Law, Delaware
            </h2>
            <div className="row">
              <h4 className="col-6 header4">
                Associate Professor of Law, Tenure Track
              </h4>
              <h4 className="col-6 header4-2">July 2001 - July 2003</h4>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">Thomas Jefferson School of Law</h2>
            <div className="row">
              <h4 className="col-6 header4">
                Visiting Associate Professor of Law,
                <br />
                Director of Academic Support,
                <br />
                Director of Diversity Affairs
              </h4>
              <h4 className="col-6 header4-2">1999 - 2000</h4>
            </div>
          </div>
          <div className="rowLight">
            <h2 className="header2">Thomas M. Cooley Law School</h2>
            <div className="row">
              <h4 className="col-6 header4">
                Assistant Professor of Law, Tenure Track
              </h4>
              <h4 className="col-6 header4-2">1996 - 1999</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
