import React from 'react';

class Home extends React.Component {
  constructor(props){
  super(props);
};

render () {
  return (
    <div className="view-container">
      <div id="home-container">
        <div className="row" id="prof-pic-wrapper">
          <div id="about-wrapper">
            <div id="about-header-box">
              <div id="about-header">About Me</div>
            </div>
            <div id="about-list-box">
              <ul id="about-list">
                <li className="about-item">Commercial Real Estate Broker</li>
                <li className="about-item">Chief Legal Counsel</li>
                <li className="about-item">Corporate Attorney</li>
                <li className="about-item">Professor</li>
                <li className="about-item">Novelist</li>
              </ul>
              <p id="caption">
                Cheryl is a business generalist. She creates real estate wealth
                opportunites which enable banks, nonprofit joint venturists,
                REIT's and partnerships through construction, operation and
                acquistion to provide affordable housing for families seniors
                and students. Which really means she uses her knowledge of real
                estate and business to find beautiful homes that normal people 
								can actually afford to rent or own.
              </p>
              <p id="personal">
                A native Pittsburgher, Mrs. Lee is passionate about Steeler
                football, but has been closely involved and connected to the San
                Diego community for the past 35 years. She served on the North
                County Housing Foundation, Esperanza Community Housing
                Association, and the San Diego Urban Bankers(now the Urban
                Financial Services Coalition) Boards of Directors and remains
                active on the Board of Directors of Bay Vista. She was chosen to 
								participate in the White House Community Leaders Initiative in 2011.
              </p>
            </div>
          </div>
          <img id="cheryl-main" src={require("./Images/CherylLeeMain.jpg")} />
        </div>
        <div className='row'>
					<div id='testHeader-box'>
            <div id='testHeader'>Testimonials</div>
					</div>
				</div>
				<div className='row'>
					<div id='testimonials-box'>
						<div className='row'>
								<div className='testimonial-col'>
								  <div className='testimonial'>
                  "Cheryl is an incredible professional in talent, diversity of skills, 
									reputation and demeanor. In addition to being one of the hardest working 
									lawyers I've ever partnered with, she is enormously generous with her time 
									and expertise, always willing to counsel and assist everyone from entrepreneurs 
									to experts. She is an invaluable asset to her organization; able to strategize 
									and innovate, while continuing a devotion to Bay Vista's vision and 
									mission-----serving those who's hopes and dreams to succeed, live well and be 
									happy are rooted in her own humble origins."
								  <br /><br />
                  <div className='testBy'>-Brehnen Knight, Chief of Strategic Partnerships and Lisa Knight, 
									Director of Operations, both of Engage Youth, Co. <a href="http://www.engageyouth.com" target="_blank" id='test-link'>www.engageyouth.com</a></div>
								  </div>                    
								</div>
							  <div className='testimonial-col'>
									<div className='testimonial'>
									"Working with Cheryl on nonprofit, educational and real estate endeavors has 
									been engaging and rewarding. She demonstrates the highest level of integrity, 
									character and work ethic-a sometime rare combination today. She is resourceful 
									ad unconstrained by traditional boundaries when focused on an end some might see 
									as a distant goal. Her approach, combining her corporate legal expertise, usiness 
									savvy, teaching tenure, and real estate exposure, translates into profits for 
									every endeavor she's involved in. As a participant, I continue to look forward to 
									the wealth of business development opportunities she's created for Bay Vista, its 
									subsidiaries and those who partner with her."
									<br /><br />
									<div className='testBy'>-Michael Roberts, Founder & Lead Instructor, San Diego Code School. 
									<a href="http://www.sdcs.io" target="_blank" id='test-link'>www.sdcs.io</a></div>
									</div>
								</div>
							  <div className='testimonial-col'>
									<div className='testimonial'>
									"Professor Lee, I just wanted to inform you that I passed the California Bar 
									Exam! I still consider you a mentor and I wanted to thank you for your wisdom 
									and support. I remember you advised me not to attend a certain law school. 
									Considering the fact that school is no longer ABA certified, I'd say that advice 
									was invaluable. Furthermore, I have been able to have a prosperous law school and 
									law clerk experience. I cannot begin to appreciate where I have come without 
									recognizing and thanking the people that have contributed to this success; you 
									have contributed to my success since the day you gave me my first LSAT prep book. 
									I am sure I will be seeing you down the road."
									<br /><br />
									<div className='testBy'>-Thank you, Julian X. Camper</div>
									</div>
								</div>
							</div>
						</div>
				  </div>
      </div>
    </div>
  );
}
}

export default Home;