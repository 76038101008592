import React from "react";

class Footer extends React.Component {
     
render () {
  return (
		<div className='view-container'>           
      <div id="footer-container">
				<div className='row'>
        <div id="networks-wrap">
          <div className='row' id='networks'>
            <a className="glpyh-link" href="https://bvista.org/" target='_blank'>
              <img id="BV" src={require("./Glyphs/BV-white.png")} />
            </a>
            <a className="glpyh-link" href="https://synergycentrecoworks.com/" target='_blank'>
              <img id="Synergy" src={require("./Glyphs/Synergy-logo-wnbg.png")} />
            </a>
          </div>
        </div>
				<div id="name-wrap">
          <div className="quote">
            Attorney  |  Real Estate Broker  |  Professor
          </div>
				</div>
        <div id="contact-wrap">
          <div className='row'>
            <div className="contact-info">
              Office: (760)-781-1393 <br />
              Direct: (619)-804-2144
              <br />
              crl@cherylrlee.com
            </div>
            <div className="contact-info" id='address'>
              P.O. Box 9172
              <br />
              Rancho Santa Fe,
              <br /> CA 92067
            </div>
            <a className="glpyh-link" href="https://www.linkedin.com/in/cheryl-r-lee-b5701a16/" target='_blank'>
              <img id="LinkedIn" src={require("./Glyphs/linkedin-3-xxl.png")} />
            </a>
          </div>
				</div>
      </div>
    </div>
		</div>
    );
  }
}

export default Footer;
