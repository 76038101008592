import React from "react";


export default class Achievements extends React.Component {
  render() {
    return (
      <div className="overallContainer">
        <div className="customBox">
        <h1 className="pageTitle">Awards</h1>
        <div className="row">
        <div className="rowLight col-12">  
          <div className="block">
            <h3>Cambridge Who’s Who</h3>
          </div>
        </div>  
        <div className="rowLight col-12"> 
          <div className="block">
            <h3>Cited as Professor of the Year by BLSA at Golden State School 
							of Law
						</h3>
          </div>
        </div>
        <div className="rowLight col-12">
          <div className="block">
            <h3>AALS Directory of Law Teachers</h3>
          </div>
        </div> 
        <div className="rowLight col-12">
          <div className="block">
            <h3>Who’s Who Among American Law Students</h3>
          </div>
        </div>
        <div className="rowLight col-12">  
          <div className="block">
            <h3>Outstanding Young Woman of America</h3>
          </div>
        </div>
        <div className="rowLight col-12">   
          <div className="block">
            <h3>Member Appellate Moot Court Board</h3>
            <h5>Duquesne University School of Law</h5>
          </div>
        </div>  
        <div className="rowLight col-12"> 
          <div className="block">
            <h3>Feature Writer JURIS</h3>
            <h5>Duquesne School of Law Publication</h5>
          </div>
        </div>
        <div className="rowLight col-12">  
          <div className="block">
            <h3>The Saddler Scholarship</h3>
            <h5></h5>
          </div>
        </div>
        <div className="rowLight col-12">  
          <div className="block">
            <h3>The Oliver L. Johnson Book Scholarship</h3>
            <h5></h5>
          </div>
        </div>
        </div>
        <h1 className="pageTitle">Publications</h1>
        <div className="row">
          <div className="rowLight col-12"> 
					<a className='publication-link' href="https://repository.law.umich.edu/cgi/viewcontent.cgi?article=1136&context=mjrl" target="_blank"> 
          <img className="blockImage" src="../../css/images/Constitutional Cash_ Are Banks Guilty of Racial Profiling in Implementing the United States Patriot Act_.png"></img>
          </a>
						<div className="block2">
              <h3>“Constitutional Cash: Are Banks Guilty of Racial Profiling in Implementing the United States Patriot Act?”</h3>
              <h5>11 University of Michigan Journal of Race & Law, 2</h5>
              <h5>2006</h5>
            </div>
          </div>
          <div className="rowLight col-12"> 
					<a className='publication-link' href="https://law-journals-books.vlex.com/" target='_blank'> 
          <img className="blockImage2" src="../../css/images/cyberbanking.jpg"></img>
					</a>
            <div className="block2Alternate">
              <h3>“Cyberbanking: A New Frontier for Discrimination?”, Vol 26, Rutgers Computer and Law Technology Journal, No.2 (2000).</h3>
              <h5>Vol 26, Rutgers Computer and Law Technology Journal, No.2</h5>
              <h5>2000</h5>
            </div>
          </div>
          <div className="rowLight col-12">
					<a className='publication-link' href="https://scholarlycommons.law.cwsl.edu/cgi/viewcontent.cgi?article=1233&context=cwlr" target="_blank">  
          <img className="blockImage" src="../../css/images/amalgamate.jpg"></img>
					</a>
            <div className="block2">
              <h3>“The Amalgamation of the Southern California Banking Industry: San Diego a Microcosm”</h3>
              <h5>35 California Western Law Review, No. 41 (1998). Cited in Summer 2000, by Financial Services Reform, 37 Harvard Journal on Legislation, 579</h5>
              <h5>1998</h5>
            </div>
          </div>
          <div className="rowLight col-12">  
          <a className='publication-link'>
          <img className="blockImage2" src="../../css/images/JURIS article pg 1.png"></img>
          </a>
            <div className="block2Alternate">
              <h3>“Elect or Appoint; Juris Talks to the Judges”</h3>
              <h5>JURIS, Duquesne School of Law Legal Magazine, Vol.18, No.2, Winter 1983, 18.2</h5>
              <h5>1983</h5>
            </div>
          </div>
        </div>
        </div>
      </div>  
    );
  }
}

