import React from 'react';

class NavBar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isHamburgerOpen: false,  
    }
    this.handleBurgerClick = this.handleBurgerClick.bind(this)
    }

    handleBurgerClick(){
    this.setState(  { isHamburgerOpen: ! this.state.isHamburgerOpen } )
		};
		
render(){
  const { isHamburgerOpen } = this.state;
  return (
    <div> 
      <div className='nav-container' >
        <nav className='ag-nav-bar'>
          <ul className='nav-links'>                                  
            <li className='nav-link-li'><a className='nav-link-a' href='#/'>Home</a></li>
            <li className='nav-link-li'><a className='nav-link-a' href='#/novel'>Novel</a></li>                
            <li className='nav-link-li'><a className='nav-link-a' href='#/bayvista'>Bay Vista</a></li>
            <li className='nav-link-li'><a className='nav-link-a' href='#/Credentials'>Credentials</a></li>
            <li className='nav-link-li'><a className='nav-link-a' href='#/Achievements'>Achievements</a></li>
            <li className='nav-link-li'><a className='nav-link-a' href='#/Affiliations'>Affiliations</a></li>
            <li className='nav-link-li'><a className='nav-link-a' href='#/Contact'>Contact</a></li>
          </ul>
        </nav>
      </div> 
			<div className='ag-container'>
				<div className='burger-button' onClick={this.handleBurgerClick}><i class='fas fa-bars'></i></div>
					<nav className = { `nav ${isHamburgerOpen ? 'show' : ''}` } >
					<div onClick={this.handleBurgerClick} className='close-menu'><i className='fas fa-times'/></div>
						<ul className='nav-items'>
							<li className='nav-list'><a className='menu-link' href='#/'>Home</a></li>
							<li className='nav-list'><a className='menu-link' href='#/novel'>Novel</a></li>
							<li className='nav-list'><a className='menu-link' href='#/bayvista'>Bay Vista</a></li>
							<li className='nav-list'><a className='menu-link' href='#/Credentials'>Credentials</a></li>
							<li className='nav-list'><a className='menu-link' href='#/Achievements'>Achievements</a></li>
							<li className='nav-list'><a className='menu-link' href='#/Affiliations'>Affiliations</a></li>
							<li className='nav-list'><a className='menu-link' href='#/Contact'>Contact</a></li>
						</ul>
					</nav>
			</div>
    </div>          
    );  
  }
}

export default NavBar;
