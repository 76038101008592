import React, { Component } from "react";

export default class Contact extends React.Component {
  render() {
    return (
      <div className="customBox"> 
        <div className="contact-container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeXq1VoD4JcBEfqBubA_I89r_Xxn4IG8N2tsxQ2LPkzBzLucQ/viewform?embedded=true"
            width="700"
            height="1100"
            frameborder="0"
            marginheight="1px"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
      </div>  
    );
  }
}
