import React from "react";

export default class BayVista extends React.Component {
  render() {
    return (
      <div className="overallContainer">
        <div className="customBox">
          <div class="BV-Hero">   
            <div className='row' id='BV-row'><img id='BVPageLogo'src={require("./Glyphs/BV-white.png")} /> </div>
            <div>&nbsp;</div>
            <p id='BV-quote'>Bay Vista concentrates on expanding its vision of affordable housing to new segments of our communities. </p>   
          </div>
          <div id='red-divider'></div>
            <div id="BV-textbox">
              <img id="cheryl-BV" src={require("./Images/CherylLee1.jpg")} />
              <p id="BV-info">  
                <div className='BV-heading'>Cheryl currently serves as CEO and Chief Legal Officer at <a href="http://www.bvista.org" target="_blank" className='BV-link'>Bay Vista</a>.</div>
                She began as Counsel to the Board of Directors of Bay Vista on
                corporate governance matters, Board duties & protections, and
                conflict of interests.
                <div>&nbsp;</div>
                <div className='BV-heading'>Cheryl's Involvement in BVista Real Estate...</div>
                In February 2008, Cheryl began to guide Bay Vista's real estate
                development, ownership, and management opportunities, low income
                tax credit use, HUD 236/221/250 Use agreements, HUD
                Project-based Section 8 and Tax Exempt Bonds. She managed the
                $21.4 million dollar sale of Bay Vista's first real estate
                development, a 268 unit affordable housing project regulated by
                the U.S. Department of Housing and Urban Development. She then 
								managed and guided the investments made from the sale of Bay Vista's 
								Logan Avenue property. She continues to negotiate the real estate
                sales and acquisitions of the company's commercial and affordable housing
                projects. For the past 12 years she has been responsible for the
                planning, leverage, and development of Bay Vista in the Senior
                and Multifamily affordable real estate market, which has
                included acquisitions of multiple commercial properties, contract
                negotiation and drafting, securing construction sites, the
                corresponding construction loans, identifying revitalization properties,
                redevelopment projects, and organizing the participation of
                community partners.
                <div>&nbsp;</div>
                <div className='BV-heading'>Current Responsibilities...</div>
                She is currently responsible for Bay
                Vista's $15 million dollar single family housing development
                project Lisbon Vista Heights, as well as Bay Vista's community
                inspired venture - Synergy CoWorks Centre in Escondido, CA and 
								Southeast San Diego - Encanto. She manages and resolves daily 
								legal matters which affect Bay Vista and its seven property-based 
								subsidiaries, including any necessary 
								involvement in litigation. Cheryl also directs the 
								grant seeking function of the non-profit to create and protect the
                longevity of the non-profit's housing and community based social programs. The Synergy 
								CoWorks Centres were created to continue the legacy of community service 
								Bay Vista began 55 years ago. While Bay Vista continues its work as a 
								501c3 affordable housing  group; Synergy is  designed to enrich the San 
								Diego & Escondido communities by providing warm, inviting collaborative 
								spaces which create synergistic interaction between leaders and 
								entrepreneurial focused businesses.  Synergy offers an exciting 
								environment to form partnerships, share strategies, and make connections 
								which support ideas and innovative exchanges. 
                <div>&nbsp;</div>
                <div className='BV-heading'>Under Cheryl's Tenure...</div>
                Under Cheryl's tenure, Bay Vista now owns and operates seven
                subsidiary corporations which collectively own a variety of tax
                exempt public benefit and affordable housing properties. Bay Vista recently acquired an 18,000sq ft commercial property and has 
								begun the development of Lisbon Vista Village at 6785 Imperial 
								Ave in San Diego into a multipurpose community and educational center.
              </p>
            </div>
          </div>
        </div>
    );
  }
}
