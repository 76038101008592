import React from "react";

export default class Novel extends React.Component {
  render() {
    return (
      <div className="overallContainer">
        <div className="customBox">
          <div className="N-Hero">   
            <h1 id='N-title'>As Time Flies</h1>
          </div>
          <div id='red-divider'></div>
            <div id="BV-textbox">
              <img id="novel-cover" src={require("./Images/ATFBookCover.jpg")} />
              <p id="BV-info">  
								<br />
                <div className='para-heading'>AS TIME FLIES - By <i className='italic-head'>C. Rennette Lee, Esq.</i></div>
								<div>&nbsp;</div>
								<br />
								<div className='para-heading'>AUTHOR</div>
                As Time Flies is an intersection between (Waiting to Exhale and The Rainmaker). 
								It is a romantic legal thriller set in Pittsburgh, Pennsylvania. The novel 
								opens with a flashback to the 1977 murder of one of the Academy Girls. C. Rennette 
								Lee has been a corporate attorney and a law professor at several ABA accredited law schools. 
								She was born and raised in Pittsburgh during the same time as the As Time Flies characters. 
								Her work has been previously published in various legal journals and cited by Harvard. 
								Carmen Browning and the Academy girls will live again in the sequels to As the Time Flies.
                <div>&nbsp;</div>
								<br />
								<br />
                <div className='para-heading'>SYNOPSIS</div>
                In the years after Dina’s murder the struggle to hold it together tore her classmates 
								apart. The Academy girls, Carmen, Ana, Tasha, Nikki and Susan buried Dina 
								knowing their lives will never be the same.
                <div>&nbsp;</div>
								The homicidal monster followed Dina for years. He strangled her with the belt from 
								her own coat like an animal, as if he never laid eyes on her before. Dragged 
								her off into the woods, violated her body, with his hands and sticks. 
								Ana wants to squeeze the life out of him the same way he clamped his huge 
								grotesque dark hands around Dina’s throat. He is hideous and creepy. Even now 
								years later, when Ana closes her eyes, she can see Dina’s body. Dina's hands 
								bruised and torn from fighting him. That horrible, big, purple, blue-green 
								and yellow knot to the left of her forehead where he hit her with the log. 
								Every sign of how violent her death had been was evident. Ana could never forget it.  
								Neither will any of the Academy girls. Their memories permeate every lunch together, every phone call, every greeting and goodbye.
								<div>&nbsp;</div>
								All these years have made them powerful women. Career women. Competent, 
								accomplished, secure.  Whole, well-adjusted, loving each other. Or so 
								they believe. Hardly can they acknowledge the riptide beneath their 
								friendship. Can they continue loving their men, their children, themselves 
								without dealing with this? Who will make them face their issues if not each 
								other?  Nikki, the Baptist saint hides her distaste for Susan the conscious-less 
								Ho, but finds compassion for Tasha, the princess in constant pursuit of low 
								life hustlers. Ana wouldn’t know a barbequed pig foot now if it fell 
								outta’ her momma’s purse. Carmen trapped in a world of Versace and Robb Report 
								allure can't remember what the love of a man is like. Love lives for her only in the 
								eyes of a three-year-old.
                <div>&nbsp;</div>
								Crisis has brought the chickens home to roost. The Academy girls need each other 
								like a cast away in the Pacific ocean needs a drink of pure water. 
								They will repair their friendship. If only to solve all their legal and 
								moral quagmires. They must confront this killer turned stalker. Can they 
								find any peace left in their souls? Can they love their 
								children as they should, and Raymond and Samuel and Todd like these men 
								deserve to be loved.
								<div>&nbsp;</div>
                <div className='para-heading'>PRIMARY CHARACTERS</div>
                Attorney Carmen Browning is a beautiful divorcee, a high-powered partner 
								in a law firm she founded. Enormously successful and attractive, she exudes competence, 
								driven by vivid memories of her poverty-stricken childhood. Her 
								calling is corporate law. Now circumstances demand Carmen’s mastery in a 
								capital murder trial in which she must represent a drug dealer with a 
								hidden connection to her. Simultaneously, she must unravel the intricacies of a 
								complicated medical malpractice suit, all without losing custody of her 
								three year old daughter Leslie, to her flamboyant, unscrupulous ex-husband. 
								Now 40, Carmen pledges to reorient her focus from materialism, to 
								God, raise her daughter, and restore her friendships with the Academy 
								girls.
                <div>&nbsp;</div>
								Now a glamorous personal shopper, <i className='italic-head'>Deaconness Nikki Richardson’s </i> 
								obesity robbed her of her youth and catapulted her into a woman even she didn’t recognize. 
								Samuel, her husband loves Nikki as unconditionally as he loves the Lord. 
								Or does he? Could Nikki’s secret unravel 20 years of marital 
								commitment? Their son, Joshua, rarely admits it but he can’t bear the thought 
								of disappointing his devoutly Christian parents. So what must he do about his 
								pregnant girlfriend? Will learning of the disgrace and deceit in their 
								mother’s past affect his sister, Tania?
								<div>&nbsp;</div>
								<i className='italic-head'>Dr. Susan Braithwaite </i>fights her moments as a confused miscengenate’. Susan 
								spurns lovers as effortlessly as she drinks a cup of Starbucks each morning. 
								Preoccupied with saving her career, she wonders... What’s different about 
								Raymond St. James?  Is it his Daufauskie Island Geechee roots, his rugged 
								smile, or his academic pedigree? 
								<div>&nbsp;</div>
								<i className='italic-head'>Flight Attendant Tasha Tribodeau</i>, a recurring addict of Street Brother Syndrome, 
								knows the only thing that could come between former football great Todd 
								Tucker and her love, is his four year old daughter Calle. Calle’s blood could 
								seal Tasha’s fate. Can Tasha handle her own haunting memories of Dina?
								<div>&nbsp;</div>
								<i className='italic-head'>Judge Anastacia Baron Jenkins </i>husband’s money, power and class landed her a 
								judicial appointment, now his appetite for gold digging fillies may cost her 
								the bench. She’s been assigned a capital murder trial, featuring a 
								defendant she despises, who is represented by her old high school nemesis 
								Carmen Browning.
								<div>&nbsp;</div>
								<i className='italic-head'>Andre Patterson </i>the drug dealer turned Banker, is bitter and resentful that 
								his mother deserted him even before he was born. She unknowingly set him on 
								the path to his destruction... and anyone in his wake. Now Andre is facing 
								the death penalty and only his mother may be able to save him from meeting 
								his maker from the chair.  
								<div>&nbsp;</div>  
            </p>
            </div>
        </div>
    </div>
    );
}
}
